import { LogModule } from './../modules/log/log.module';
import { HttpClientModule } from '@angular/common/http';
import { InterceptorsModule } from '../modules/interceptors/interceptors.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WelcomeComponent } from './welcome/welcome.component';
import { SigninOidcComponent } from './signin-oidc/signin-oidc.component';
import { RedirectSilentRenewComponent } from './redirect-silent-renew/redirect-silent-renew.component';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../modules/pipes/pipes.module';
import { PanelBarModule } from '@progress/kendo-angular-layout';


@NgModule({
   declarations: [
      AppComponent,
      WelcomeComponent,
      SigninOidcComponent,
      RedirectSilentRenewComponent
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      RouterModule,
      HttpClientModule,

      PanelBarModule,
      InterceptorsModule.forRoot(),
      PipesModule,
      LogModule,

      AppRoutingModule,
   ],
   providers: [],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
