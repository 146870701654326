import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';

import { slideInAnimation } from './app.animation';
import { environment } from '../environments/environment';
import { AppService } from '../services/app/app.service';
import { OpenIdConnectService } from '../services/app/open-id-connect.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [slideInAnimation]
})
export class AppComponent implements OnInit, OnDestroy {
  isAuthorized: boolean;
  logoName: string;
  title: string;
  businessName: string;
  fcaNumber: string;
  returnUrl: string;
  route: ActivatedRoute;
  familyName = '';
  givenName = '';
  cclUrl = environment.cclUrl;
  prod: boolean = environment.production;

  loading = true;
  constructor(
    router: Router,
    private appService: AppService,
    private openIdConnectService: OpenIdConnectService
  ) {
    router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
  }

  ngOnInit() {
    console.log('initialise app component');
    this.appService.userLoaded$.subscribe((isUserLoaded: boolean) => {
      if (isUserLoaded) {
        this.isAuthorized = true;
        this.familyName = this.appService.acFamilyName;
        this.givenName = this.appService.acGivenName;
      }
    });

    this.appService.acTitle = 'Academy Service';
    this.appService.acObservableTitle.subscribe(title => {
      this.title = title;
    });

    this.appService.dealershipLoaded$.subscribe(
      (isDealershipLoaded: boolean) => {
        if (isDealershipLoaded) {
          this.logoName =
            '/FSAImages/DealerLogos/' +
            this.appService.currentDealership.logoName;
          this.businessName = this.appService.currentDealership.businessName;
          this.fcaNumber = this.appService.currentDealership.fcaNumber;
        }
      }
    );
  }

  ngOnDestroy(): void {}

  login() {
    console.log('start login');
    this.openIdConnectService.triggerSignIn();
  }

  refreshSession() {
    console.log('start refreshSession');
  }

  logout() {
    console.log('start logoff');
    this.openIdConnectService.triggerSignout();
  }

  checkRouterEvent(routerEvent: Event): any {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    } else if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.loading = false;
    }
  }
}
