// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true, /* Production is only used to allow re-takes when already passed atm. */
  academyAPI:'http://localhost:1001',
  idSrv: 'https://localhost:44333/identity',
  clientUrl: 'http://localhost:1000',
  cclUrl: 'http://localhost:8100',
  openIdConnectSettings: {
    authority: 'https://localhost:44333/identity',
    client_id: 'academyimplicit',
    redirect_uri: 'http://localhost:1000/signin-oidc',
    post_logout_redirect_uri: "http://localhost:1000/",
    scope: 'openid profile address roles dealershipName acadmyserviceapi',
    response_type: 'id_token token',
    filterProtocolClaims: true,
    loadUserInfo: true,
    revokeAccessTokenOnSignout: true,
    monitorSession: true,
    automaticSilentRenew: true,
    silent_redirect_uri: 'http://localhost:1000/redirect-silentrenew'
  },
  rotorEdgeWidth: 450,
  minDialogWidth: 250
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
