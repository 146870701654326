import { AppService } from './../../services/app/app.service';
import { OnInit, OnDestroy, Component } from '@angular/core';
import { Roles } from '../../enums/roles.enum';
import { environment } from './../../../../academy-client/src/environments/environment'

@Component({
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit, OnDestroy {
  cclUrl = environment.cclUrl;
  constructor(private appService: AppService) {
  }

  isAdminstrator = false;
  accessReports = false;
  acUser = false;
  ngOnInit() {
    this.appService.userLoaded$.subscribe((isAuthorizaed: boolean) => {
      if ( isAuthorizaed ) {
       this.isAdminstrator = this.appService.acIsRole(Roles.DealerAdministrator)
        || this.appService.acIsRole(Roles.AcademyAdministrator)
        || this.appService.acIsRole(Roles.Administrator);
        this.accessReports = this.appService.acIsRole(Roles.AccessReports);
        this.acUser = this.appService.acIsRole(Roles.AccessAllAreas);
      }
    });
  }
  ngOnDestroy(): void {
  }
}
