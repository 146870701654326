export enum LogLevel {
    All = 0,
    Debug = 1,
    Info = 2,
    Warn = 3,
    Error = 4,
    Exception = 5,
    Fatal = 6,
    Off = 7
}

