import { WriteOutJsonInterceptor } from "./write-out-json.interceptor";
import { HandleHttpErrorInterceptor } from "./handle-http-error.interceptor";
import { EnsureAcceptHeaderInterceptor } from "./ensure-accept-header-interceptor";
import { EnsureCacheControlHeaderInterceptor } from "./ensure-cache-control-header-interceptor";
import {
  NgModule,
  Optional,
  SkipSelf,
  ModuleWithProviders
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpsRequestInterceptor } from "./http-request.interceptor";

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class InterceptorsModule {
  constructor(@Optional() @SkipSelf() parentModule: InterceptorsModule) {
    if (parentModule) {
      throw new Error(
        "InterceptorModule is already loaded. Import it in the AppModule only"
      );
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: InterceptorsModule,
      providers: [
        { provide: "Window", useValue: window },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpsRequestInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: EnsureAcceptHeaderInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: EnsureCacheControlHeaderInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: WriteOutJsonInterceptor,
          multi: true
        },

        {
          provide: HTTP_INTERCEPTORS,
          useClass: HandleHttpErrorInterceptor,
          multi: true
        }
      ]
    };
  }
}
