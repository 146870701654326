import { LogService } from './../log/log.service';
import { catchError, retry } from 'rxjs/operators';
import { Injectable, Injector } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError, observable, of } from "rxjs";

@Injectable()
export class HandleHttpErrorInterceptor implements HttpInterceptor {
 private request: any;
  constructor(private logService: LogService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.request = request;
    return next.handle(request).pipe(
      retry(1),
      catchError(err=>this.handlerError(err)));
  }

  
  private handlerError(err: HttpErrorResponse) {
    if (err.error instanceof Error) {
      // client-side or network error
      const errorToLog = `Http error (client/network). ${err.message}`;
      console.log(err.error);
    } else {
      // unsuccesful response code
      const errorToLog = `Http error (unsuccessful reponse). Message: ${
        err.message
      }, status code: ${err.status}, body: ${JSON.stringify(err.error)} `;
      console.log(err.error);
    }

    this.logService.error(
      "Handle http error interceptor",
      "handlerError",
      err.message,
      err.url
    );

    if (err.status === 422) {
      // throw the error body
      return throwError(err);
    } else {
      return throwError(err.error === null ? err.message : err.error);
    }    
  }
}
