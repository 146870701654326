import { LogPublisherService } from './log-publisher.service';
import { LogLevel } from "./log-level.enum";
import { Injectable } from "@angular/core";
import { LogEntry } from "./log-entry";
import { LogPublisher } from "./log-publisher";

@Injectable()
export class LogService {
  
  // public properties
  level: LogLevel = LogLevel.All;
  logWithDate: boolean = true;
  publishers: LogPublisher[];

  constructor(
    private publishersService: LogPublisherService) {
      // Set all the publishers into the local array
      this.publishers = this.publishersService.publishers;
  }
  debug(location: string, action:string, message:string, inputData:string) {
    this.writeToLog(LogLevel.Debug, location,action,message, inputData);
  }

  info(location: string, action:string, message:string, inputData:string) {
    this.writeToLog(LogLevel.Info,location,action,message,inputData);
  }

  warn( location: string, action:string, message:string, inputData:string) {
    this.writeToLog(LogLevel.Warn,location,action,message,inputData);
  }

  error( location: string, action:string, message:string, inputData:string) {
    this.writeToLog(LogLevel.Error, location,action,message,inputData);
  }

   exception(location: string, action:string, message:string, inputData:string) {
    this.writeToLog(LogLevel.Exception, location,action,message,inputData);
  }
  fatal( location: string, action:string, message:string,inputData:string) {
    this.writeToLog(LogLevel.Exception,location,action,message,inputData,);
  }

  log( location: string, action:string, message:string, inputData:string) {
    this.writeToLog(LogLevel.All, location,action,message,inputData);
  }

  clear():void{
      for(let logger of this.publishers){
          logger.clear();
      }
  }
 
  private shouldLog(level: LogLevel): boolean {
    let ret: boolean = false;

    if (this.level !== LogLevel.Off && level >= this.level) {
      ret = true;
    }

    return ret;
  }

  private writeToLog(logLevel: LogLevel, location: string, action:string, message:string, inputData:string) {
    if (this.shouldLog(logLevel)) {
      let entry: LogEntry = new LogEntry();
      // let pltName =  JSON.stringify(this.plt.versions());
      // if(pltName === "{}"){
      let  pltName = "Web Browser"
      // }
      entry.logLevelId = logLevel;
      entry.platform = pltName;
      entry.location = location;
      entry.logWithDate = this.logWithDate;
      entry.action = action;
      entry.message = message;
      entry.inputData = inputData;

      // Log the value to all publishers
      for(let logger of this.publishers){
          logger.log(entry).subscribe(response => console.log(response));
      }
    }
  }
}
