import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'acBooleanYesNo'
})
export class AcBooleanYesNoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === undefined || value === null) {
      return 'No';
    }

    return value === true ? 'Yes' : 'No';
  }


}
