import { Observable, of } from 'rxjs';
import { LogPublisher } from "./log-publisher";
import { LogEntry } from './log-entry';

export class LogConsole extends LogPublisher{
   log(record: LogEntry): Observable<boolean>{
       // Log to the console
       console.log(record.buildLogString());
       return of(true);
   }

   clear(): Observable<boolean>{
       console.clear();

       return of(true);
   }
}
