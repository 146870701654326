import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ACDatePipe } from './ac-date.pipe';
import { ACCPDTimePipe } from './ac-cpd-time.pipe';
import { AcBooleanYesNoPipe } from './ac-boolean-YesNo.pipe';



@NgModule({
   imports: [
      CommonModule
   ],
   declarations: [
      ACDatePipe,
      ACCPDTimePipe,
      AcBooleanYesNoPipe
   ],
   providers: [
      ACDatePipe,
      ACCPDTimePipe
   ],
   exports: [
      ACDatePipe,
      ACCPDTimePipe,
      AcBooleanYesNoPipe
   ]
})
export class PipesModule { }
