import { Roles } from "./../../enums/roles.enum";
import { AppService } from "./app.service";
import { OpenIdConnectService } from "./open-id-connect.service";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanLoad,
  Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  LoadChildren
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class RequireAuthenticatedUserRouteGuardService
  implements CanActivate, CanLoad {
  constructor(
    private openIdConnectService: OpenIdConnectService,
    private appService: AppService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkLoggedIn(next.routeConfig.loadChildren);
  }

  canLoad(route: Route): boolean {
    return this.checkLoggedIn(route.loadChildren);
  }

  private checkLoggedIn = (loadChildren: LoadChildren): boolean => {
    if (this.openIdConnectService.userAvailable) {
      if (
        loadChildren ===
          "modules/management/management.module#ManagementModule" ||
        loadChildren === "modules/histories/histories.module#HistoriesModule"
      ) {
        return (
          this.appService.acIsRole(Roles.DealerAdministrator) ||
          this.appService.acIsRole(Roles.AcademyAdministrator) ||
          this.appService.acIsRole(Roles.Administrator)
        );
      } else {
        return true;
      }
    }

    // trigger signin
    this.openIdConnectService.triggerSignIn();
    return false;
  };
}
