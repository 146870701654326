import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OpenIdConnectService } from '../../services/app/open-id-connect.service';


@Component({
  templateUrl: './signin-oidc.component.html',
  styleUrls: ['./signin-oidc.component.css']
})
export class SigninOidcComponent implements OnInit {

  constructor(
    private router: Router,
    private openIdConnectService: OpenIdConnectService,
  ) { }

  private readonly newProperty = 'An error happened: User wasn\'t loaded';

  ngOnInit() {
    this.openIdConnectService.userLoaded$.subscribe((userLoaded) => {
      if (userLoaded) {
        this.router.navigate(['welcome']);
      } else {
        console.log(this.newProperty);
      }
    });
    this.openIdConnectService.handleCallBack();
  }

}

