import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'acCPDTime'
})
export class ACCPDTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === undefined || value === null) {
      return '0 hours';
    }

    return `${value} hours`;
  }

}
