import { LogLevel } from "./log-level.enum";

export class LogEntry {
    // Public properties
    
    id:             number;
    acServiceLogId: number;
    logLevelId:     number;

    public get loglevel() : LogLevel {
        return this.logLevelId === null ? LogLevel.Debug : this.logLevelId;
    }

    platform:       string;
    location:       string;
    action:         string;
    inputData:      string;
    message:        string;
    createdBy:      Date;
    createdWhen:    Date;
    entryDate:      Date = new Date();
    logWithDate:    boolean = true; 

    buildLogString():string{
        let ret: string = "";

        if(this.logWithDate){
            ret = new Date() + " - ";
        }

        ret += "platform: " + this.platform
        ret += " - location: " + this.location;
        ret += " - action: " + this.action;
        ret += " - Type: " + LogLevel[this.logLevelId];
        ret += " - message: " + this.message;
        ret += " - inputData: " + this.inputData; 
        return ret;
    }

    private formatParams(params: any[]){
        let ret: string = params.join(",");
        
        if(params.some(p => typeof p === "object" )){
            ret = "";
            for(let item of params){
                ret += JSON.stringify(item) + ","
            }
        }
        return ret;
    }
}
