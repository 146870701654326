import { environment } from './../../environments/environment.dev';
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { LogPublisher } from "./log-publisher";
import { LogConsole } from "./log-console";
import { LogLocalStorage } from "./log-local-storage";
import { LogWebApi } from './log-web-api';

@Injectable()
export class LogPublisherService {
  constructor(private httpClient: HttpClient) {
    this.buildPublishers();
  }

  publishers: LogPublisher[] = [];
  buildPublishers(): void {
    // Create an instance of the LogConsole class
    if(! environment.production)
    {
      this.publishers.push(new LogConsole());
    }
    
    // // Create an instance of the LogLocalStorage class
    // this.publishers.push(new LogLocalStorage());

    // Create an instance of the LogWebApi class
    this.publishers.push(new LogWebApi(this.httpClient));
  }
}
