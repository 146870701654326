import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHeaders, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";

import { Observable } from "rxjs";
import { ACDatePipe } from "../pipes/ac-date.pipe";
import { map, catchError, tap } from 'rxjs/operators';
import { OpenIdConnectService } from "../../services/app/open-id-connect.service";

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  private headers: HttpHeaders;
  constructor(
    private datePipe: ACDatePipe,
    private openIdConnectService: OpenIdConnectService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   
    const started = Date.now();
    let authHeader = '';

    if (this.openIdConnectService.userAvailable) {

    let token = this.openIdConnectService.user.access_token; //this.securityService.getToken();
  
      authHeader = this.openIdConnectService.user.token_type + " " + token;
    }
   
    // const authReq = req.clone({
    //   setHeaders: {
    //     Authorization: authHeader,
    //     "Content-Type": "application/json",
    //     'Access-Control-Allow-Origin': '*',
    //     "Access-Control-Allow-Headers": "X-Requested-With, Content-Type, Accept, Origin, Authorization",
    //     'Access-Control-Allow-Methods': 'GET,POST,PUT,PATCH,DELETE,HEAD,OPTIONS'
    //   }

    const authReq = req.clone({
      headers:req.headers.set('Authorization', authHeader)
    });
    return next.handle(authReq).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          let end = Date.now();
          let datePipeValue = this.datePipe.transform(end, "YYYY-MM-DD HH:mm:ss");
          console.log(`Request for ${req.urlWithParams} took ${end - started}ms on ${datePipeValue}.`);
        }
      })
    );
  }
};
