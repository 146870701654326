import { LogPublisherService } from './log-publisher.service';
import { LogService } from './log.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    LogService,
    LogPublisherService]
})
export class LogModule {}
