import { environment } from './../../environments/environment';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { LogPublisher } from "./log-publisher";
import { LogEntry } from "./log-entry";
import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";


export class LogWebApi extends LogPublisher {
  constructor(private httpClient: HttpClient) {
    super();

    this.location = `${environment.academyAPI}/api/log`;
  }
  
  log(record: LogEntry): Observable<boolean> {
    return this.httpClient
      .post(this.location, record)
      .pipe(
        map((data:LogEntry)=>{
          return true;
        }),
        catchError(this.handlerError)
        )
      
  }

  clear(): Observable<boolean> {
    return of(true);
  }

  private handlerError(error: HttpErrorResponse) {
    let errors: string[] = [];
    let msg: string = "";

    msg = "Status: " + error.status;
    msg += " - Status Text: " + error.statusText;
    if(error){
        msg += " - Exception Message: " + error.message;
    }

    errors.push(msg);

    console.error("An error occurred", errors);

    return Observable.throw(errors);
  }
}
