import { SelectiveStrategyService } from './../services/app/selective-strategy.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SigninOidcComponent } from './signin-oidc/signin-oidc.component';
import { RedirectSilentRenewComponent } from './redirect-silent-renew/redirect-silent-renew.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { RequireAuthenticatedUserRouteGuardService } from '../services/app/require-authenticated-user-route-guard.service';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
    canActivate: [RequireAuthenticatedUserRouteGuardService]
  },
  {
    path: 'training',
    loadChildren: 'modules/training/training.module#TrainingModule',
    canActivate: [RequireAuthenticatedUserRouteGuardService],
    data: { preload: true }
  },
  {
    path: 'reports',
    loadChildren: 'modules/reports/reports.module#ReportsModule',
    canLoad: [RequireAuthenticatedUserRouteGuardService]
  },
  {
    path: 'administration', 
    loadChildren: 'modules/administration/administration.module#AdministrationModule',
    canActivate: [RequireAuthenticatedUserRouteGuardService]
  },  
  { path: 'signin-oidc', component: SigninOidcComponent },
  { path: 'redirect-silentrenew', component: RedirectSilentRenewComponent },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [RequireAuthenticatedUserRouteGuardService]
  },
  {
    path: '**',
    redirectTo: 'welcome',
    canActivate: [RequireAuthenticatedUserRouteGuardService]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: SelectiveStrategyService
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
