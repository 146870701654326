import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { OpenIdConnectService } from './open-id-connect.service';
import { map, catchError } from 'rxjs/operators';
import * as _ from 'underscore';
import { environment } from '../../environments/environment';
import { IDealership } from '../../interfaces/share/i-dealership';
@Injectable({
  providedIn: 'root'
})
export class AppService {
  private title: string;
  private behaviorSubject = new BehaviorSubject<string>("");
  private familyName: string;
  private givenName: string;
  private businessName: string;
  private cclNumber: string;
  private userId: string;
  private logoName: string;
  private fcaNumber: string;
  private dealershipId: number;
  private uri = environment.academyAPI + '/api/common/dealership';
  _currentDealership: IDealership = null;
  private _dealershipLoaded$ : ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private http: HttpClient,
    private openIdConnectService: OpenIdConnectService) {
    this._dealershipLoaded$.next(null);
    //this.getUserData().subscribe(isAuthenticated => console.log("Has user data been Loaded: ", isAuthenticated));
    
    this.openIdConnectService.userLoaded$.subscribe((isUserLoaded: boolean) => {
      if (isUserLoaded) {
        this.getDealershipById().subscribe((dealership) => {
          this._currentDealership = dealership;
          console.log("Has loaded the current dealership's information:", JSON.stringify(this._currentDealership));
          this._dealershipLoaded$.next(this._currentDealership !== null);
        });
      }
    });
  }

  get dealershipAvailable():boolean{
    return this._currentDealership !== null;
  }
  get dealershipLoaded$(): Observable<boolean> {
    return this._dealershipLoaded$.asObservable();
  }

  get userLoaded$(): Observable<boolean> {
    return this.openIdConnectService.userLoaded$.asObservable();
  }

  get acUserRoles():string[]{
    return this.openIdConnectService.user.profile.role || null;
  }


  get currentDealership(): IDealership {
    return this._currentDealership;
  }

  get acUserId(): string {
    return this.openIdConnectService.user.profile.sub || null;
  }

  get acUserAvailable(): boolean {
    return this.openIdConnectService.userAvailable;
  }

  get acFamilyName(): string {
    return this.openIdConnectService.user.profile.family_name || null;
  }

  get acGivenName(): string {
    return this.openIdConnectService.user.profile.given_name || null;
  }  

  get acObservableTitle(): Observable<string> {
    return this.behaviorSubject.asObservable();
  }

  set acTitle(value: string) {
    if (value !== this.title) {
      this.title = value;
      this.behaviorSubject.next(this.title);
    }
  }

  getTitle(): Observable<string> {
    this.behaviorSubject = new BehaviorSubject<string>(this.title);
    let titleObservered = this.behaviorSubject.asObservable();

    return titleObservered.pipe(
      map(title => {
        console.log("return title: " + title);
        return title;
      }),
      catchError(this.handlerError)
    );
  }

  acIsRole(roleName:string):boolean{
    return _.contains(this.openIdConnectService.user.profile.role, roleName);
  }
  private getDealershipById(): Observable<IDealership> {
    return this.http.get<IDealership>(this.uri).pipe(
      catchError(this.handlerError)
    );
  }

  private handlerError(err: HttpErrorResponse) {
    console.log(err.message);
    return Observable.throw(err.message);
  }
}
