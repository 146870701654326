import { Injectable } from '@angular/core';
import {UserManager, User}from 'oidc-client'
import { ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OpenIdConnectService {

  private userManager: UserManager = new UserManager(environment.openIdConnectSettings);
  private currentUser: User;

  userLoaded$ = new ReplaySubject<boolean>(1);

  constructor() {
    this.userManager.clearStaleState();
    this.userManager.events.addUserLoaded(user => {
      console.log('User loaded', user);
      this.currentUser = user;
      this.userLoaded$.next(true);
    });

    this.userManager.events.addUserUnloaded((e) => {
      console.log('User unloaded');
      this.currentUser = null;
      this.userLoaded$.next(false);
    });
  }

  get userAvailable():boolean{
    return this.currentUser != null;
  }

  get user():User{
    return this.currentUser;
  }

  triggerSignIn() {
    this.userManager.signinRedirect().then(() => {
      console.log('Redirection to signin triggered.');
    });
  }

  handleCallBack() {
    this.userManager.signinRedirectCallback().then((user) => {
      console.log('Callback after signin handled', user);
    });
  }

  handleSilentCallback() {
    this.userManager.signinSilentCallback().then(user => {
      this.currentUser = user;
      console.log('Callback after silent signin handled.', user);
    }).catch((exception) => {
      console.log(exception);
    });
  }

  triggerSignout() {
    this.userManager.signoutRedirect().then(() => {
      console.log('Redirection to sign out trigger');
    });
  }
}